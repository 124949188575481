<template>
  <div class="table-sorting-icon">
    <span
      class="material-icons-outlined"
      :style="{ opacity: state ? 0.75 : 0.25 }"
    >
      {{
        state === 'asc'
          ? 'expand_more'
          : state === 'desc'
          ? 'expand_less'
          : 'unfold_less'
      }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'TableSortingIcon',
  props: {
    state: {
      type: [String, Number],
      required: true
    }
  }
}
</script>
<style scoped>
.table-sorting-icon {
  position: absolute;
  right: 0.25em;
  top: 50%;
  transform: translate(0, -50%);
}
.material-icons-outlined {
  font-size: 1.25em;
}
</style>
